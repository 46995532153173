import { SIMPLE_ISO_DATE_FORMAT, SIMPLE_ISO_TIME_FORMAT } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { Event } from "@libry-content/types";
import { localizedFormat } from "../../utils/date";
import isSameDay from "date-fns/isSameDay";

export const getEventScheduleDetails = (event: Pick<Event, "eventSchedule">, locale: FrontendLocale) => {
  const { startsAt, endsAt, allDay } = event.eventSchedule ?? {};
  if (!startsAt || !endsAt) return {};

  const isMultiDayEvent = !isSameDay(new Date(startsAt), new Date(endsAt));

  return {
    startDate: localizedFormat(startsAt, SIMPLE_ISO_DATE_FORMAT, locale),
    startTime: localizedFormat(startsAt, SIMPLE_ISO_TIME_FORMAT, locale),
    endDate: localizedFormat(endsAt, SIMPLE_ISO_DATE_FORMAT, locale),
    endTime: localizedFormat(endsAt, SIMPLE_ISO_TIME_FORMAT, locale),
    allDay: !!allDay,
    isMultiDayEvent,
    startsAt,
    endsAt,
  };
};
