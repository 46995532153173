import { Box, BoxProps, ChakraProps, HStack } from "@biblioteksentralen/react";
import { isRepeatedEventOccurence, ResolvedEventSummary, targetAudiences } from "@libry-content/common";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useSitePalette } from "../../utils/useSitePalette";

const registrationLabels: Record<NonNullable<ResolvedEventSummary["registration"]>, string> = {
  ticket: "Krever billett",
  registration: "Påmelding",
  open: "Gratis",
};

export const EventBadges = ({ event, ...chakraProps }: { event: ResolvedEventSummary } & ChakraProps) => {
  const { ts, t } = useTranslation();
  const palette = useSitePalette();

  return (
    <HStack gap=".2rem" flexWrap="wrap" {...chakraProps}>
      <Badge {...palette.colors.lightaccent1.css}>{ts(event.eventType?.label)}</Badge>
      {event.targetAudiences?.map((code) => (
        <Badge {...palette.colors.lightaccent2.css} key={code}>
          {ts(targetAudiences[code])}
        </Badge>
      ))}
      <Badge {...palette.colors.lightaccent3.css}>{registrationLabels[event.registration ?? "open"]}</Badge>
      {isRepeatedEventOccurence(event) && <Badge {...palette.colors.lightaccent4.css}>{t("Serie")}</Badge>}
    </HStack>
  );
};

const Badge = (props: BoxProps) => (
  <Box fontWeight={600} padding=".2em .5em .1em" borderRadius=".25em" textTransform="uppercase" {...props} />
);
