import { DateHelper, eventTimeString, ResolvedEventSummary, timeString } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { localizedFormat } from "../../utils/date";
import { getEventScheduleDetails } from "./utils";

// TODO, jobb med tekster og oversett
export const eventScheduleToString = (
  event: Pick<ResolvedEventSummary, "eventSchedule">,
  lang: FrontendLocale
): string => {
  const { isMultiDayEvent, allDay, startsAt, endsAt } = getEventScheduleDetails(event, lang);

  if (isMultiDayEvent) {
    if (allDay) {
      return `${localizedFormat(startsAt, "do MMM", lang)} - ${localizedFormat(endsAt, "do MMM", lang)}`;
    }
    const endsToday = new DateHelper(endsAt).isSameDay(new Date());
    if (endsToday) {
      return `Varer til ${timeString(endsAt, lang)} i dag`;
    }
    const hasStarted = new DateHelper(startsAt).isBeforeNow();
    if (hasStarted) {
      return `Hele dagen. Varer tom ${localizedFormat(endsAt, "do MMM p", lang)}`;
    }
    return `${timeString(startsAt, lang)} - ${localizedFormat(endsAt, "do MMM p", lang)}`;
  }

  if (allDay) {
    return "Hele dagen";
  }

  return eventTimeString(lang, event);
};
