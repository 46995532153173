import { colors } from "@biblioteksentralen/react";
import { DateHelper, ResolvedEventSummary, UTCDateToNorwegian } from "@libry-content/common";
import differenceInDays from "date-fns/differenceInDays";
import isAfter from "date-fns/isAfter";
import { localizedFormat } from "../../utils/date";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { getEventScheduleDetails } from "./utils";
import startOfDay from "date-fns/startOfDay";

export const useCurrentEventStatus = (event: ResolvedEventSummary): { text: string; color: string } | undefined => {
  const { t, lang } = useTranslation();
  const { startsAt, endsAt, allDay, startDate } = getEventScheduleDetails(event, lang);
  const now = new Date();

  if (event.eventStatus === "cancelled") return undefined;
  if (!startsAt || !endsAt) return undefined;

  const antallDagerTil = differenceInDays(new Date(startDate), startOfDay(UTCDateToNorwegian(now)));

  if (antallDagerTil >= 6) return undefined;
  if (antallDagerTil >= 2)
    return {
      color: colors.statusGreen,
      text: t("På {weekday}", { weekday: localizedFormat(startsAt, "EEEE", lang) }),
    };
  if (antallDagerTil >= 1) return { color: colors.statusGreen, text: allDay ? t("Starter i morgen") : t("I morgen") };

  const { date: eventStart, minutesFromNow: minutesUntilEventStarts } = new DateHelper(startsAt);

  const eventStarted = isAfter(now, eventStart);
  const eventEnded = isAfter(now, new Date(endsAt));

  if (eventEnded) return { color: colors.statusRed, text: t("Arrangementet er avsluttet") };
  if (eventStarted) return { color: colors.statusYellow, text: allDay ? t("Pågår nå") : t("Arrangementet pågår") };
  if (allDay) return { color: colors.statusGreen, text: t("I dag") };

  const hoursUntilEventStarts = minutesUntilEventStarts / 60;

  if (hoursUntilEventStarts > 5)
    return {
      color: colors.statusYellow,
      text: t("I dag"),
    };

  if (hoursUntilEventStarts > 1)
    return {
      color: colors.statusYellow,
      // The hours must be rounded up, otherwise an event that starts in e.g. 1.1 hours will show as
      // "Starter om under 1 timer", which is the wrong time and grammar.
      text: t("Starter om under {hours} timer", { hours: Math.ceil(hoursUntilEventStarts).toFixed() }),
    };
  if (minutesUntilEventStarts > 45)
    return {
      color: colors.statusYellow,
      text: t("Starter om under en time"),
    };
  if (minutesUntilEventStarts > 2)
    return {
      color: colors.statusYellow,
      text: t("Starter om under {minutter} minutter", { minutter: Math.ceil((minutesUntilEventStarts + 1) / 5) * 5 }),
    };
  return { color: colors.statusYellow, text: t("Starter nå") };
};
